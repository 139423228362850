<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" help-page-component="InvoicesOverview"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-tabs
                        v-model="invoicesOverviewCurrentTab"
                        color="success"
                        background-color="grey lighten-2"
                        slider-color="appic-green"
                    >
                        <v-tab key="ds-ss" @click="changeExportParams('ds-ss')" v-show="$can('read','Invoice')">{{ $t('message.dsSs') }}</v-tab>
                        <v-tab key="ws" @click="changeExportParams('ws')" v-show="$can('access','LocalSales')">{{ $t('message.whSales') }}</v-tab>
                        <v-tab key="cs" @click="changeExportParams('cs')" v-show="$can('access','DebitNotes')">{{ $t('message.cs') }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="invoicesOverviewCurrentTab">
                        <v-tab-item key="ds-ss-list" v-show="$can('read','Invoice')">
                            <v-card flat>
                                <DirectInvoicesListingNew></DirectInvoicesListingNew>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="ws-list" v-show="$can('access','LocalSales')">
                            <v-card flat>
                                <WarehouseInvoicesListingNew></WarehouseInvoicesListingNew>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item key="cs-list" v-show="$can('read','Invoice')">
                            <v-card flat>
                                <CommissionInvoicesListingNew></CommissionInvoicesListingNew>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
    import {mapFields} from "vuex-map-fields";
    import LoadingComponent from "Components/Appic/LoadingComponent";

    const CommissionInvoicesListingNew = () => ({
        component: import("../../../components/Appic/CommissionInvoicesListingNew"),
        loading: LoadingComponent
    });
    const DirectInvoicesListingNew = () => ({
        component: import("../../../components/Appic/DirectInvoicesListingNew"),
        loading: LoadingComponent
    });
    // const ExportTable = () => import("../../../components/Appic/ExportTable");
    const WarehouseInvoicesListingNew = () => ({
        component: import("../../../components/Appic/WarehouseInvoicesListingNew"),
        loading: LoadingComponent
    });

    export default {
        name: "InvoicesOverview",
        components: {WarehouseInvoicesListingNew, CommissionInvoicesListingNew, DirectInvoicesListingNew},
        title: '',
        data() {
            return {
                loader: false,
                active: null,
                colsToDelete: {
                    start_col: 10,
                    ncols: 1
                },
                fileName: 'direct_invoices',
                hasAddFunction: true,
                invoiceType: 'ds-ss',
                document: 'invoices',
                tableId: 'directInvoiceTable'
            }
        },
        computed: {
            ...mapFields('runtime',{
                invoicesOverviewCurrentTab: 'invoicesOverviewCurrentTab'
            })
        },
        methods: {
            changeExportParams(invoiceType){
                switch (invoiceType) {
                    case 'ds-ss':
                        this.colsToDelete = {start_col: 10, ncols: 1}
                        this.fileName = 'direct_invoices'
                        this.hasAddFunction = true
                        this.document = 'invoices'
                        this.invoiceType = 'ds-ss'
                        this.tableId = 'directInvoiceTable'
                        break
                    case 'ws':
                        this.colsToDelete = {start_col: 10, ncols: 1}
                        this.fileName = 'warehouse_invoices'
                        this.hasAddFunction = true
                        this.document = 'localsales'
                        this.invoiceType = 'ws'
                        this.tableId = 'warehouseInvoiceTable'
                        break
                    case 'cs':
                        this.colsToDelete = {start_col: 10, ncols: 1}
                        this.fileName = 'commission_invoices'
                        this.hasAddFunction = true
                        this.document = 'debitnotes'
                        this.invoiceType = 'cs'
                        this.tableId = 'debitNoteTable'
                        break
                }
            },
            newInvoice (invoiceType) {
                let tab = window.open('/v1/' + invoiceType + '/add','_blank')
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            }
        },
        created() {
            if(this.$can('access','LocalSales')){
                if(this.invoicesOverviewCurrentTab == null) {
                    this.invoicesOverviewCurrentTab = 1
                }
            }
            if(this.$can('read','Invoice')){
                if(this.invoicesOverviewCurrentTab == null) {
                    this.invoicesOverviewCurrentTab = 0
                }
            }
        },
        mounted(){
            this.$title = this.$t('message.titles.invoices')
        }
    }
</script>

<style>
    .v-data-table td {
        padding: 0 5px;
    }
    .v-data-table td {
        padding: 0 5px;
        font-size: 0.8rem !important;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
</style>